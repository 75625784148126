import React from "react";
import { Edit as EditIcon } from "react-feather";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Checkbox,
  IconButton,
  Link,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { type TopicModel, TopicStatus } from "../types/graphql";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Label from "./Label";

/**
 * Types
 */
interface Props {
  topic: TopicModel;
  onDuplicateTopic: (topicId: string) => void;
}

/**
 * Styles
 */
const useStyles = makeStyles({
  title: {
    maxWidth: "240px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const TopicsPageTopicDetails: React.FC<Props> = ({ topic, onDuplicateTopic }: Props) => {
  const classes = useStyles();

  const renderers = {
    category: () => {
      if (topic?.theme?.isResource) {
        return <Label variant="info">Resource</Label>;
      }
      if (topic?.theme?.isStandard) {
        return <Label variant="info">Standard</Label>;
      }
      if (topic?.theme?.isTopicLibrary) {
        return <Label variant="info">Topic Library</Label>;
      }
    },
    status: () => {
      if (topic?.status === TopicStatus.Current) {
        return <Label variant="success">Current</Label>;
      }
      if (topic?.status === TopicStatus.ComingSoon) {
        return <Label variant="warning">Coming Soon</Label>;
      }
      if (topic?.status === TopicStatus.Inactive) {
        return <Label variant="error">Inactive</Label>;
      }
    },
  };

  return (
    <TableRow key={topic.id} hover>
      <TableCell padding="checkbox">
        <Checkbox />
      </TableCell>

      <TableCell>
        <Box display="flex" alignItems="center">
          <Link color="inherit" component={RouterLink} to={`/topic/${topic?.id}/edit`}>
            <Typography variant="body2" className={classes.title}>
              {topic?.title}
            </Typography>
          </Link>
        </Box>
      </TableCell>

      <TableCell>
        <Box display="flex" alignItems="center">
          {renderers.category()}
        </Box>
      </TableCell>

      <TableCell>
        <Box display="flex" alignItems="center">
          {topic.theme?.id && (
            <Link color="inherit" component={RouterLink} to={`/theme/${topic.theme.id}/edit`}>
              <Typography variant="body2" className={classes.title}>
                {topic?.theme?.title}
              </Typography>
            </Link>
          )}
        </Box>
      </TableCell>

      <TableCell>
        <Box display="flex" alignItems="center">
          {renderers.status()}
        </Box>
      </TableCell>

      <TableCell align="right">
        <IconButton
          size="large"
          onClick={() => {
            onDuplicateTopic(topic.id);
          }}
        >
          <SvgIcon fontSize="small">
            <ContentCopyIcon />
          </SvgIcon>
        </IconButton>
        <Link component={RouterLink} to={`/topic/${topic?.id}/edit`}>
          <IconButton size="large">
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default TopicsPageTopicDetails;
