import React, { useEffect, useState } from "react";
import { useDebounce } from "react-use";

import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useOnOffSwitch, usePagination } from "../helpers/hooks";

import { DEFAULT_OFFSET, ROWS_PER_PAGE_DEFAULT, ROWS_PER_PAGE_OPTIONS } from "../helpers/const";

import { Spacing } from "../types/enum";
import { type TopicModel, useAdminListPaginatedTopicsQuery } from "../types/graphql";

import DuplicateTopic from "src/components/DuplicateTopic.tsx";
import TopicsPageTopicDetails from "../components/TopicsPageTopicDetails";
import TopicsPageCreateTopicCont from "../containers/TopicsPageCreateTopicCont";

const TopicsPage: React.FC = () => {
  const [query, setQuery] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [open, onOpen, onClose] = useOnOffSwitch();
  const [isOpen, openDuplicateTopic, closeDuplicateTopic] = useOnOffSwitch();
  const [topicId, setTopicId] = useState<string>("");
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const [limit, setLimit] = useState<number>(ROWS_PER_PAGE_DEFAULT);

  useDebounce(
    () => {
      setSearchValue(query);
    },
    1000,
    [query],
  );

  const handleChange = (event: any) => {
    setQuery(event.target.value);
  };

  const { loading, data, error, refetch } = useAdminListPaginatedTopicsQuery({
    fetchPolicy: "network-only",
    variables: {
      filters: {
        text: searchValue,
        limit,
        offset,
      },
    },
  });

  const { page, rowsPerPage, items, onChangePage, onChangeRowsPerPage } = usePagination<TopicModel>(
    data?.adminListPaginatedTopics?.items as TopicModel[],
  );

  useEffect(() => {
    setOffset(page * rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setLimit(rowsPerPage);
  }, [rowsPerPage]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error || !data) {
    return <p>An error happened while loading topics</p>;
  }

  const onDuplicateTopic = (topicId: string) => {
    setTopicId(topicId);
    openDuplicateTopic();
  };

  return (
    <>
      <Box mb={Spacing.m}>
        <Typography variant="h3" color="textPrimary">
          All Topics
        </Typography>
      </Box>
      <Box mb={Spacing.m} display="flex" alignItems="center" justifyContent="space-between">
        <Box mb={Spacing.m} sx={{ width: "50%" }}>
          <TextField
            fullWidth
            label="Search topic by title or by ID"
            variant="outlined"
            value={query}
            onChange={handleChange}
          />
        </Box>
        <Button variant="contained" color="secondary" onClick={onOpen}>
          Create topic
        </Button>
      </Box>
      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center" p={Spacing.xxl}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox />
                </TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Theme</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((topic: TopicModel) => (
                <TopicsPageTopicDetails
                  key={topic.id}
                  topic={topic}
                  onDuplicateTopic={onDuplicateTopic}
                />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  page={page}
                  count={data?.adminListPaginatedTopics?.total}
                  onPageChange={onChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Card>

      <TopicsPageCreateTopicCont open={open} onClose={onClose} limit={limit} offset={0} />

      <DuplicateTopic
        topicId={topicId}
        isOpen={isOpen}
        close={closeDuplicateTopic}
        refetch={refetch}
      />
    </>
  );
};

export default TopicsPage;
